import { useCallback } from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import { ICardCTA, ReactTableColumns } from '@appTypes';
import { CardCTA } from '@components/CardCTA';
import { Link } from '@components/Common.styles';
import { StatusPill } from '@components/StatusPill';
import { Translate } from '@hooks/useI18n/types';
import { filterDateRange } from '@utils/filterDateRange';
import { IOrderTableData } from './types';

interface IConfirmDispatchButtonProps {
  ctaProps: ICardCTA;
  setDispatchedUuid: (uuid: string) => void;
  uuid: string;
}

const ConfirmDispatchButton = ({
  ctaProps,
  setDispatchedUuid,
  uuid,
}: IConfirmDispatchButtonProps) => {
  const clickHandler = useCallback(() => {
    setDispatchedUuid(uuid);
    ctaProps.clickHandler();
  }, [ctaProps, setDispatchedUuid, uuid]);

  return <CardCTA {...ctaProps} clickHandler={clickHandler} />;
};

interface IGetOrdersTableColumnsProps {
  ctaProps: ICardCTA | undefined;
  isAwaitingDispatch: boolean;
  isCompass: boolean;
  isConsumerPortal: boolean;
  isMerchantPortal: boolean;
  isPagolightPro: boolean;
  setDispatchedUuid: (uuid: string) => void;
  translate: Translate;
}

export const getOrdersTableColumns = ({
  ctaProps,
  isAwaitingDispatch = false,
  isCompass = false,
  isConsumerPortal,
  isMerchantPortal,
  isPagolightPro,
  setDispatchedUuid,
  translate,
}: IGetOrdersTableColumnsProps): ReactTableColumns<IOrderTableData> => {
  const columnHelper = createColumnHelper<IOrderTableData>();
  const paymentPlanReference = columnHelper.accessor('paymentPlanReference', {
    header: translate('TITLE.PAYMENT_PLAN_ID'),
    cell: ({ row }) => (
      <Link to={row.original.orderId}>{row.original.paymentPlanReference}</Link>
    ),
  });

  const mediobancaExternalId = columnHelper.accessor('mediobancaExternalId', {
    header: translate('TITLE.EXTERNAL_ID'),
    cell: ({ row }) => (
      <Link to={row.original.orderId}>{row.original.mediobancaExternalId}</Link>
    ),
  });

  const merchantName = columnHelper.accessor('merchantName', {
    header: translate('OPS_PORTAL.LABEL.MERCHANT_NAME'),
  });

  const orderId = isCompass ? mediobancaExternalId : paymentPlanReference;

  const getMerchantReferenceKey = () => {
    if (isMerchantPortal && !isPagolightPro) {
      return 'TITLE.YOUR_INVOICE_NUMBER';
    }
    return 'TITLE.INVOICE_NUMBER';
  };

  const merchantReference = columnHelper.accessor('merchantReference', {
    header: translate(getMerchantReferenceKey()),
  });

  const orderValue = columnHelper.accessor('orderValue', {
    header: translate(
      isPagolightPro
        ? 'PAGE.ORDERS.INITIAL_ORDER_VALUE'
        : 'PAGE.ORDERS.ORDER_VALUE',
    ),
  });

  const dateConfirmed = columnHelper.accessor('dateConfirmed', {
    header: translate('TITLE.DATE_CONFIRMED'),
    filterFn: (row, _, filterValue) =>
      filterDateRange({ accessorKey: 'dateRaw', filterValue, row }),
  });

  const status = columnHelper.accessor('status', {
    header: translate('TITLE.ORDER_STATUS'),
    cell: ({ row }) => (
      <StatusPill
        rawStatus={row.original.rawStatus}
        status={row.original.status}
      />
    ),
  });

  if (isConsumerPortal) {
    return [
      orderId,
      merchantName,
      merchantReference,
      orderValue,
      dateConfirmed,
      status,
    ];
  }

  const customerName = columnHelper.accessor('customerName', {
    header: translate(
      isPagolightPro
        ? 'PAGOLIGHT_PRO.TITLE.CUSTOMER_NAME'
        : 'TITLE.CUSTOMER_NAME',
    ),
  });

  const originationChannel = columnHelper.accessor('originationChannel', {
    header: translate('TITLE.CHANNEL'),
    enableSorting: false,
  });

  const ctaColumn = columnHelper.display({
    cell: ({ row }) =>
      ctaProps && row.original.delayFinalisation === true ? (
        <ConfirmDispatchButton
          ctaProps={ctaProps}
          setDispatchedUuid={setDispatchedUuid}
          uuid={row.original.orderId}
        />
      ) : null,
    id: 'ctaColumn',
    enableSorting: false,
  });

  if (isAwaitingDispatch) {
    return [
      orderId,
      merchantReference,
      customerName,
      orderValue,
      dateConfirmed,
      originationChannel,
      ctaColumn,
    ];
  }

  return [
    orderId,
    merchantReference,
    customerName,
    orderValue,
    dateConfirmed,
    status,
    originationChannel,
  ];
};

import { ValueOf } from '@appTypes';
import { IAmountObjectNullable } from '@schemas/opsPortal/types/monetaContract';
import { PaymentTypes } from '@utils/constants';

interface IGetPaymentModalData {
  amount?: IAmountObjectNullable;
  contract_amount?: IAmountObjectNullable;
  contract_uuid?: string;
  current_order_amount?: IAmountObjectNullable;
  external_uuid?: string;
  payment_plan_reference?: string;
  system_remainder?: IAmountObjectNullable;
}

interface IGetPaymentModalProps {
  data: IGetPaymentModalData;
  paymentType: ValueOf<typeof PaymentTypes>;
  settlementAmount?: IAmountObjectNullable;
}

export const getPaymentModalProps = ({
  data,
  paymentType,
  settlementAmount,
}: IGetPaymentModalProps) => {
  const {
    amount,
    contract_uuid: contractUuid,
    current_order_amount: currentOrderAmount,
    external_uuid: uuid,
    payment_plan_reference: paymentPlanId,
    system_remainder: systemRemainder,
  } = data;

  const currency = amount?.currency ?? '';

  let reimbursementMax: IAmountObjectNullable = null;
  if (amount && systemRemainder) {
    reimbursementMax = {
      amount: (
        parseFloat(amount.amount) - parseFloat(systemRemainder.amount)
      ).toFixed(2),
      currency: amount.currency,
    };
  }

  const maxPaymentAmount = {
    [PaymentTypes.MANUAL_PAYMENT]: systemRemainder,
    [PaymentTypes.PREPAYMENT]: systemRemainder,
    [PaymentTypes.REFUND]: currentOrderAmount,
    [PaymentTypes.ADJUSTMENTS]: settlementAmount,
    [PaymentTypes.REIMBURSEMENT]: reimbursementMax,
  };

  const max = maxPaymentAmount[paymentType]?.amount;
  const settlementAmountValue = settlementAmount?.amount;
  const remainingBalance = systemRemainder?.amount;

  return {
    contractUuid,
    currency,
    max,
    paymentPlanId,
    uuid,
    settlementAmountValue,
    remainingBalance,
  };
};

import { useMemo } from 'react';
import { ICardCTA } from '@appTypes';
import { ITabData } from '@components/Tabs/types';
import { useI18n } from '@hooks/useI18n/useI18n';
import { TabEventKeys, TableNames } from '@utils/constants';
import { OpsPortalDocumentTypes } from './enums';
import { getDocumentsTableColumns } from './getDocumentTableColumns';
import { mapDocumentsTabTableData } from './mapDocumentsTabTableData';
import { IDocumentsTableColumn } from './types';
import { useGetContractDocumentsQuery } from './useGetContractDocumentsQuery';
import { useGetDownloadExecutionQueries } from './useGetDownloadExecutionQueries';

interface IUseContractDocumentsTab {
  contractUuid: string;
  ctaProps?: ICardCTA;
  setDocumentDeleteDetails?: (deleteDetails: IDocumentsTableColumn) => void;
}

export const useContractDocumentsTab = ({
  contractUuid,
  ctaProps,
  setDocumentDeleteDetails,
}: IUseContractDocumentsTab): ITabData => {
  const { getLocaleDate, translate } = useI18n();

  const { data, error, isLoading } = useGetContractDocumentsQuery({
    contractUuid,
  });

  const {
    executeCustomerAgreementDownload,
    executeIntrumDownload,
    executeOnfidoDownload,
    executeOpsUserDocumentsDownload,
    executeTermsAndConditionsDownload,
  } = useGetDownloadExecutionQueries();

  const contractDocumentsColumns = useMemo(
    () =>
      getDocumentsTableColumns({
        ctaProps,
        translate,
        onDeleteClick: setDocumentDeleteDetails || (() => null),
        onDownloadClick: rowData => {
          const documentTypeExecutors = {
            [OpsPortalDocumentTypes.OnfidoFront]: executeOnfidoDownload,
            [OpsPortalDocumentTypes.OnfidoBack]: executeOnfidoDownload,
            [OpsPortalDocumentTypes.OnfidoSelfie]: executeOnfidoDownload,
            [OpsPortalDocumentTypes.OnfidoHealthCard]: executeOnfidoDownload,
            [OpsPortalDocumentTypes.IntrumIdentificationFiles]:
              executeIntrumDownload,
            [OpsPortalDocumentTypes.IntrumSigningFiles]: executeIntrumDownload,
            [OpsPortalDocumentTypes.CustomerAgreement]:
              executeCustomerAgreementDownload,
            [OpsPortalDocumentTypes.TradeInOptionAgreement]:
              executeCustomerAgreementDownload,
            [OpsPortalDocumentTypes.TermsAndConditionsFinalization]:
              executeTermsAndConditionsDownload,
            [OpsPortalDocumentTypes.TermsAndConditionsConclusion]:
              executeTermsAndConditionsDownload,
            [OpsPortalDocumentTypes.UploadedDocumentAcknowledgementOfDebt]:
              executeOpsUserDocumentsDownload,
            [OpsPortalDocumentTypes.UploadedDocumentComplaintAboutAgency]:
              executeOpsUserDocumentsDownload,
            [OpsPortalDocumentTypes.UploadedDocumentComplaintAboutLateFee]:
              executeOpsUserDocumentsDownload,
            [OpsPortalDocumentTypes.UploadedDocumentComplaintAboutMerchant]:
              executeOpsUserDocumentsDownload,
            [OpsPortalDocumentTypes.UploadedDocumentGeneralComplaint]:
              executeOpsUserDocumentsDownload,
            [OpsPortalDocumentTypes.UploadedDocumentImportantCommunication]:
              executeOpsUserDocumentsDownload,
            [OpsPortalDocumentTypes.UploadedDocumentOther]:
              executeOpsUserDocumentsDownload,
            [OpsPortalDocumentTypes.UploadedDocumentReplyToAComplaint]:
              executeOpsUserDocumentsDownload,
            [OpsPortalDocumentTypes.UploadedDocumentCreditContractSignedInstore]:
              executeOpsUserDocumentsDownload,
          };

          documentTypeExecutors[rowData.documentType](rowData);
        },
      }),
    [
      ctaProps,
      executeCustomerAgreementDownload,
      executeIntrumDownload,
      executeOnfidoDownload,
      executeOpsUserDocumentsDownload,
      executeTermsAndConditionsDownload,
      setDocumentDeleteDetails,
      translate,
    ],
  );

  const contractDocumentsData = mapDocumentsTabTableData({
    data,
    getLocaleDate,
    translate,
  });

  return {
    columns: contractDocumentsColumns,
    data: contractDocumentsData,
    eventKey: TabEventKeys.CONTRACT_DOCUMENTS,
    error,
    isLoading,
    tableName: TableNames.DOCUMENTS,
    title: translate('OPS_PORTAL.TITLE.CONTRACT_DOCUMENTS'),
  };
};

import { AxiosError } from 'axios';
import { useToast } from '@heidi-pay/heidi-common-fe/hooks';
import { Nullable } from '@heidi-pay/heidi-common-fe/types';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ICardCTA } from '@appTypes';
import { useHasPermission } from '@hooks/useHasPermission';
import { useI18n } from '@hooks/useI18n';
import { QueryKeys } from '@utils/constants';
import { Endpoints } from '@utils/enums';
import { postDataToEndpoint } from '@utils/postDataToEndpoint';

interface IUseGetPauseSettlement {
  merchantSettlementPaused?: boolean;
  uuid?: string;
}

export const useGetPauseSettlementButton = ({
  merchantSettlementPaused,
  uuid,
}: IUseGetPauseSettlement) => {
  const canPauseMerchantSettlement = useHasPermission([
    'ops_portal.can_pause_merchant_settlement',
  ]);
  const { translate } = useI18n();
  const queryClient = useQueryClient();
  const { message } = useToast(translate);
  const postValue = !merchantSettlementPaused;
  const errorMsgPartial = merchantSettlementPaused ? 'UNPAUSE' : 'PAUSE';

  const { mutate: pauseOrUnpauseMerchantSettlement } = useMutation<
    void,
    AxiosError
  >(
    () =>
      postDataToEndpoint({
        endpoint: `${Endpoints.PauseMerchantSettlement}/${uuid}`,
        requestData: { paused: postValue },
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([QueryKeys.MERCHANT_DETAILS, uuid]);
      },
      onError: () => {
        message.error(
          `STATUS.UNABLE_TO_${errorMsgPartial}_MERCHANT_SETTLEMENT`,
        );
      },
    },
  );

  const pauseMerchantSettlementButton: Nullable<ICardCTA> =
    merchantSettlementPaused !== undefined && canPauseMerchantSettlement
      ? {
          clickHandler: pauseOrUnpauseMerchantSettlement,
          key: 'pauseMerchantSettlement',
          text: merchantSettlementPaused
            ? translate('OPS_PORTAL.BUTTON.UNPAUSE_MERCHANT_SETTLEMENT')
            : translate('OPS_PORTAL.BUTTON.PAUSE_MERCHANT_SETTLEMENT'),
        }
      : null;

  return pauseMerchantSettlementButton;
};

import { useCallback } from 'react';
import { ICardCTA } from '@appTypes';
import { CardCTA } from '@components/CardCTA';
import { IDocumentsTableColumn } from './types';

interface IDeletionConfirmationButtonProps {
  ctaProps: ICardCTA;
  rowData: IDocumentsTableColumn;
  setDeleteDetails: (deleteDetails: IDocumentsTableColumn) => void;
}

export const DeletionConfirmationButton = ({
  ctaProps,
  rowData,
  setDeleteDetails,
}: IDeletionConfirmationButtonProps) => {
  const clickHandler = useCallback(() => {
    setDeleteDetails(rowData);
    ctaProps.clickHandler();
  }, [setDeleteDetails, rowData, ctaProps]);

  return <CardCTA {...ctaProps} clickHandler={clickHandler} />;
};

import { createColumnHelper, Row } from '@tanstack/react-table';
import { ICardCTA, ReactTableColumns } from '@appTypes';
import { Button } from '@components/Common.styles';
import { Translate } from '@hooks/useI18n';
import { ButtonVariant } from '@utils/enums';
import { DeletionConfirmationButton } from './DeletionConfirmationButton';
import { UploadedDocumentTypes } from './enums';
import { IDocumentsTableColumn } from './types';

interface IGetDocumentsTableColumns {
  ctaProps?: ICardCTA;
  onDeleteClick: (row: IDocumentsTableColumn) => void;
  onDownloadClick: (row: IDocumentsTableColumn) => void;
  translate: Translate;
}

export const getDocumentsTableColumns = ({
  ctaProps,
  onDeleteClick,
  onDownloadClick,
  translate,
}: IGetDocumentsTableColumns): ReactTableColumns<IDocumentsTableColumn> => {
  const columnHelper = createColumnHelper<IDocumentsTableColumn>();

  const handleDownloadClick = (row: Row<IDocumentsTableColumn>) => () =>
    onDownloadClick(row.original);

  return [
    columnHelper.accessor('timestamp', {
      header: translate('TITLE.DATE'),
    }),
    columnHelper.accessor('name', {
      header: translate('OPS_PORTAL.LABEL.NAME'),
    }),
    columnHelper.accessor('documentTypeDesc', {
      header: translate('TITLE.TYPE'),
    }),
    columnHelper.accessor('isSigned', {
      header: translate('OPS_PORTAL.TITLE.IS_SIGNED'),
      enableSorting: false,
    }),
    columnHelper.display({
      cell: ({ row }) => (
        <>
          {row.original.isSigned !== translate('GENERAL.NO') ? (
            <Button
              onClick={handleDownloadClick(row)}
              variant={[ButtonVariant.Primary, ButtonVariant.Sm]}
            >
              {translate('BUTTON.DOWNLOAD')}
            </Button>
          ) : null}
          {ctaProps &&
          UploadedDocumentTypes.includes(row.original.documentType) ? (
            <DeletionConfirmationButton
              ctaProps={ctaProps}
              rowData={row.original}
              setDeleteDetails={onDeleteClick}
            />
          ) : null}
        </>
      ),
      id: 'ctaColumn',
    }),
  ];
};

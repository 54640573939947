import { resourcesBucketUrl } from '@utils/constants';
import { IImageLogo } from './types';

// This is for any configurations we want to specify
// across all portals and originators.
export const configShared = Object.freeze({
  // This is the maximum number of buttons in a card header
  // before a menu button is used.
  maxCardHeaderCTAs: 2,
  logo: {
    altTxt: 'HeyLight',
    url: `${resourcesBucketUrl}logos/heylight_<§:variant§>_standard.svg`,
  } as IImageLogo,
});
